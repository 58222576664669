.trunk_one {
	padding: 50px 0 40px
}
.footer_clm_2 {
	padding: 0;
	background: #212730
}
.footLinkArea h5 {
	font-size: 17px;
	color: #fff;
	margin-bottom: 20px
}
.footLinkArea li, .footLinkArea li a, .foot_quickLinks li a {
	font-size: 13px;
	color: #a9adb7
}
.footLinkArea li {
	margin-bottom: 12px
}
.footLinkArea li:last-child {
	margin-bottom: 0
}
.footLinkArea li a {
	text-decoration: none
}
.phNum_bold {
	font-size: 17px!important
}
.foot_brief {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 14px;
	color: #a9adb7
}
.trunk_two {
	padding: 25px 0;
	border-top: 1px solid #787d85;
	border-bottom: 1px solid #787d85
}
.foot_quickLinks {
	display: flex;
	justify-content: center;
	flex-wrap: wrap
}
.foot_quickLinks li a {
	text-decoration: none;
	padding: 0 15px
}
.ftr_social ul {
	display: flex
}
.ftr_social ul li {
	margin-right: 15px
}
.ftr_social ul li a {
	display: inline-block
}

/* media query */
 @media screen and (min-width:320px) and (max-width:767px) {
.footer_clm_1 {
	padding: 15px 15px;
}
.ftr_looking h3 {
	font-size: 22px;
}
.ftr_tel {
	margin-bottom: 15px;
}
.ftr_looking {
	margin-bottom: 10px;
}
.ftr_tel h3 {
	font-size: 16px;
}
.trunk_one {
	padding: 15px 0 20px;
}
.footLinkArea {
	margin-top: 25px;
}
.footLinkArea h5 {
	margin-bottom: 12px;
}
.foot_quickLinks li a {
	padding: 0 5px;
}
.trunk_two {
	padding: 12px 0;
}
.footLinkArea {
	margin-top: 15px;
}
}
