
/*----------inline loader-----*/
.loader-lg {
  width: 70px;
  height: 70px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.loader-lg-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.loader-lg-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}


.loader-lg-dot:nth-child(1) { animation-delay: -1.1s; }
.loader-lg-dot:nth-child(2) { animation-delay: -1.0s; }
.loader-lg-dot:nth-child(3) { animation-delay: -0.9s; }
.loader-lg-dot:nth-child(4) { animation-delay: -0.8s; }
.loader-lg-dot:nth-child(5) { animation-delay: -0.7s; }
.loader-lg-dot:nth-child(6) { animation-delay: -0.6s; }
.loader-lg-dot:nth-child(1):before { animation-delay: -1.1s; }
.loader-lg-dot:nth-child(2):before { animation-delay: -1.0s; }
.loader-lg-dot:nth-child(3):before { animation-delay: -0.9s; }
.loader-lg-dot:nth-child(4):before { animation-delay: -0.8s; }
.loader-lg-dot:nth-child(5):before { animation-delay: -0.7s; }
.loader-lg-dot:nth-child(6):before { animation-delay: -0.6s; }


.loader-open-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.loader-open-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #f15d24;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.loader-open-dot:nth-child(1) { animation-delay: -1.1s; }
.loader-open-dot:nth-child(2) { animation-delay: -1.0s; }
.loader-open-dot:nth-child(3) { animation-delay: -0.9s; }
.loader-open-dot:nth-child(4) { animation-delay: -0.8s; }
.loader-open-dot:nth-child(5) { animation-delay: -0.7s; }
.loader-open-dot:nth-child(6) { animation-delay: -0.6s; }
.loader-open-dot:nth-child(1):before { animation-delay: -1.1s; }
.loader-open-dot:nth-child(2):before { animation-delay: -1.0s; }
.loader-open-dot:nth-child(3):before { animation-delay: -0.9s; }
.loader-open-dot:nth-child(4):before { animation-delay: -0.8s; }
.loader-open-dot:nth-child(5):before { animation-delay: -0.7s; }
.loader-open-dot:nth-child(6):before { animation-delay: -0.6s; }

.openLoader{
	width:100%; 
	height:100%;    
	height: calc(80vh);
	display: flex;
    align-items: center;
    justify-content: center; 
}


@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}


.loader-sm {
  width: 60px;
  text-align: center;
  display:inline-block;
}

.loader-sm > div {
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader-sm .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-sm .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/*----------inline loader end-----*/

.full_overlay {
    background-color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 99999;
    color: #fff;
}